import axios from "axios";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from "react-redux";
import {
  RESET_LINK_EXPIRED,
  PASSWORD_ERROR,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  GET_COUNTRYS,
  AUTH_ERROR,
  FORGET_PASSWORD_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  CLEAR_PROFILE,
  FORGOT_PASSWORD,
  LOGOUT,
  UPDATE_ORG,
  ORG_ERROR,
  CALLBACK_ERROR,
  UPDATE_CALLBACK,
  START_REG_LOADER,
  START_ORG_LOADER,
  AUTH_MODE,
  LOGIN_SANDBOX,
  LOGIN_SUCCESS_LOCAL,
  LOGIN_AUTHENITICATED,
  QR_CODE,
  QR_CODE_START,
  QR_CODE_FAIL,
  ENABLE_TWO_FACTOR,
  ENABLE_TWO_FACTOR_ERROR,
  LOGIN_SANDBOX_TWOFACTOR
} from "./types";
import setAuthToken from "../utils/tools";
import { sendLogs } from "../actions/logs";
import { env as environment } from "./environment";
import refreshAuth from "./refreshAuth";
import ls from "localstorage-slim";
import { GlobalDebug } from "../remove-consoles";



if (!environment.consoleLog) {
  GlobalDebug(false);
}

ls.config.encrypt = true;

// Get post
export const getCountries = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get(environment.verifiedcountrylist, config);

    dispatch({
      type: GET_COUNTRYS,
      payload: res.data,
    });
  } catch (err) {
    console.log("in getcountries");
    dispatch({
      type: AUTH_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//update org
let updOrgCount = 0;
export const updateOrg = (formData) => async (dispatch) => {
  dispatch({ type: START_ORG_LOADER });
  // var json = { "apikey": apikey, formData}
  try {
    const res = await axios.post(environment.updateorganization, formData);

    //console.log("res update1 " + JSON.stringify(res));
    //console.log("res update data " + JSON.stringify(res.data));
    //console.log("res update data " + JSON.stringify(res.data.error));
    if (res.data.error === true || res.data.status === 401) {
      updOrgCount = 0;
      dispatch({
        type: ORG_ERROR,
        payload: res.data,
      });
      sendLogs("Org Update failed", res.data, "actions/auth.js");
    } else {
      if (res.data) {
        updOrgCount = 0;
      }
      dispatch({
        type: UPDATE_ORG,
        payload: res.data,
      });
      sendLogs("Org Update", "Org updated successfully", "actions/auth.js");
    }
  } catch (err) {
    if (
      ls.get("refreshToken") &&
      (err.message === "Request failed with status code 401" ||
        err.message === "Network Error") &&
      updOrgCount < 5
    ) {
      updOrgCount++;
      dispatch(refreshAuth("updateOrgErr", formData, null, false));
    } else {
      updOrgCount = 0;
      console.log("in updateorg");
      dispatch({
        type: AUTH_ERROR,
        // payload: err.response
      });
      sendLogs("Org Update failed", err, "actions/auth.js");
    }
  }
};

//update org
let callbackCount = 0;
export const updateCallback = (formData) => async (dispatch) => {
  // var json = { "apikey": apikey, formData}
  //console.log("hello");
  try {
    const res = await axios.post(environment.updateorganization, formData);
    //console.log("res update1 " + JSON.stringify(res));
    //console.log("res update data " + JSON.stringify(res.data));
    //console.log("res update data " + JSON.stringify(res.data.error));
    if (res.data.error === true) {
      callbackCount = 0;
      dispatch({
        type: CALLBACK_ERROR,
        payload: res.data,
      });
    } else {
      if (res.data) {
        callbackCount = 0;
      }
      dispatch({
        type: UPDATE_CALLBACK,
        payload: res.data,
      });
    }
  } catch (err) {
    if (
      ls.get("refreshToken") &&
      (err.message === "Request failed with status code 401" ||
        err.message === "Network Error") &&
      callbackCount < 5
    ) {
      callbackCount++;
      dispatch(refreshAuth("updateCallback", formData, null, false));
    } else {
      callbackCount = 0;
      console.log("in update callback");
      dispatch({
        type: AUTH_ERROR,
        // payload: err.response
      });
    }
  }
};

// Register User
export const register =
  ({
    email,
    country,
    password,
    firstname,
    lastname,
    companyname,
    building,
    roleincompany,
  }) =>
  async (dispatch) => {
    dispatch({ type: START_REG_LOADER });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const signupdata = {};
    if (email) signupdata.email = email;
    if (country) signupdata.country = country;
    if (password) signupdata.password = password;
    if (firstname) signupdata.firstname = firstname;
    if (lastname) signupdata.lastname = lastname;
    if (companyname) signupdata.companyname = companyname;
    if (building) signupdata.building = building;
    if (roleincompany) signupdata.roleincompany = roleincompany;

    try {
      const res = await axios.post(environment.signup, signupdata, config);
      console.log("res signup " + JSON.stringify(res));
      if (res.data.message === "plz check you email") {
        dispatch({
          type: REGISTER_SUCCESS,
          payload: { payload: res.data },
        });
        sendLogs(
          "Org Register",
          "Org registered successfully",
          "actions/auth.js"
        );
      } else {
        dispatch({
          type: REGISTER_FAIL,
          payload: { payload: res.data },
        });
        sendLogs("Org Registration failed", res.data, "actions/auth.js");
      }
      // if (res.headers.authorization) {
      //   setAuthToken(res.headers.authorization);
      console.log("2222");
      // }
    } catch (err) {
      console.log("catch===>", err.response);
      dispatch({
        type: REGISTER_FAIL,
        payload: err?.response?.data,
      });
      sendLogs("Org Registration failed", err, "actions/auth.js");
    }
  };

// export const FusionRegister =
//   ({ isLoading }) =>
//   async (dispatch) => {
//     dispatch({ type: START_REG_LOADER });
//     const config = {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     };
//     const body = "";

//     try {
//       const res = await axios.post(environment.setOrgData, body, config);
//       // console.log("res signup " + JSON.stringify(res));
//       if (res.data.message === "plz check you email") {
//         dispatch({
//           type: REGISTER_SUCCESS,
//           payload: { payload: res.data, regloading: isLoading },
//         });
//         sendLogs(
//           "Org Register",
//           "Org registered successfully",
//           "actions/auth.js"
//         );
//       } else {
//         dispatch({
//           type: REGISTER_FAIL,
//           payload: { payload: res.data },
//         });
//         sendLogs("Org Registration failed", res.data, "actions/auth.js");
//       }
//       // if (res.headers.authorization) {
//       //   setAuthToken(res.headers.authorization);
//       console.log("2222");
//       // }
//     } catch (err) {
//       console.log("catch===>", err.response);
//       dispatch({
//         type: REGISTER_FAIL,
//         payload: err.response.data,
//       });
//       sendLogs("Org Registration failed", err, "actions/auth.js");
//     }
//   };


  //old login for local and hi
  export const login = (email, password, authMode) => async (dispatch) => {
    console.log('authmode inside action login',authMode)
    //dispatch({ type: START_LOGIN_LOADER });
    console.log("authMode " + authMode);
    ls.remove("token");
    ls.remove("alldata");
    ls.remove("apikey");
    ls.remove("email");
    ls.remove("orgid");
    ls.remove("roles");
    ls.set("roles", "null");
    ls.remove("stripeid");
    ls.remove("planid");
    ls.clear();
    
    const idempotencyKey = uuidv4();
    const config = {
      headers: {
        "Content-Type": "application/json",
        // "Idempotency-Key": idempotencyKey
      },
    };
    // const body = JSON.stringify({ email, password });
    const body = { email, password };
    try {
      // const res = await axios.post(environment.login, body, config);

      //for test above is main
      const res= await axios.post(environment.login,body,config);
      // ls.set('Authorization', setAuthToken);
      // ls.get('Authorization');
      // console.log("res  " + JSON.stringify(res));
      console.log("res **** " + JSON.stringify(res));
      console.log("res login data " + res.data);
      console.log("res login " + res.data.error);
      console.log("authmode::",authMode)
      console.log("authMode type:", typeof authMode);
      console.log("res header " + JSON.stringify(res.headers));
      // if(res.data.error === "email is invalid" || res.data.message === "password is invalid" || res.data.message === "Please provide correct password!" || res.data.message != "undefined" || res.data.message != undefined)
      if (res.data.error === true) {
        // //console.log("hii");
        dispatch({
          type: LOGIN_FAIL,
          payload: { payload: res.data },
        });
        sendLogs("Org Login failed", res.data, "actions/auth.js");
      } else if (res.data.statusCode === 242 ) {
        
        if (res.data.sandbox === false || res.data.sandbox === "1") {
          
          console.log('inside if auth')
          console.log("login success!!!")
          dispatch({
            type: LOGIN_AUTHENITICATED,
            payload: { headers: res.headers, payload: res.data, email },
          })
          sendLogs(
            "Org Login success",
            "Org logged in successfully",
            "actions/auth.js"
          );
        } else {
          console.log("sandbox");
          dispatch({
            type: LOGIN_SANDBOX,
            payload: { headers: res.headers, payload: res.data,email },
          });
          sendLogs(
            "Sandbox Login",
            "Sandbox logged in successfully",
            "actions/auth.js"
          );
        }
      } else {
        // //console.log("res " + JSON.stringify(res.headers));
        //console.log("res " + res);
        console.log("login fail")
        dispatch({
          type: LOGIN_FAIL,
          payload: { payload: res.data },
        });
      }
    } catch (error) {
      console.log("error block",error.response?.data);
      dispatch({
        type: LOGIN_FAIL,
        payload: { payload: error.response?.data.message },
      });
    }
    // }
  };


  //two factor login
  export const twoFactorLogin = (email, otp,twoFactorId, authStatus) => async (dispatch) => {
    //dispatch({ type: START_LOGIN_LOADER });
    ls.remove("token");
    ls.remove("alldata");
    ls.remove("apikey");
    ls.remove("email");
    ls.remove("orgid");
    ls.remove("roles");
    ls.set("roles", "null");
    ls.remove("stripeid");
    ls.remove("planid");
    ls.clear();
    
    const config = {
      headers: {
        "Content-Type": "application/json",
        
      },
    };
    let body;
    const bypassEmails = environment.bypassEmails;
    //bypass email of deepak for testing 
     if (bypassEmails.includes(email)) {
      console.log("Calling the specified API for deepak@diro.io");
      try {
          const response = await axios.post(environment.twofactorAutomatedOtp, { email });
          console.log("API response for deepak@diro.io:", response.data);
          console.log("otp",response.data.data.code)
          console.log("body of the 2fa "+ body);
          body={email,fusionotp:response.data.data.code,twoFactorId:response.data.data.twoFactorId}
         
      } catch (error) {
          console.error("Error calling the specified API:", error.response?.data || error.message);
      }
  }else{
      body = { email,fusionotp:otp,twoFactorId };
    }
   
    try {
      // const res = await axios.post(environment.login, body, config);

      //for test above is main
      console.log("calling an api")
      const res= await axios.post(environment.twoFactorLogin,body,config);
      // ls.set('Authorization', setAuthToken);
      // ls.get('Authorization');
      // console.log("res  " + JSON.stringify(res));
      console.log("res **** " + JSON.stringify(res));
      console.log("res login data " + res.data);
      console.log("res login " + res.data.error);
      console.log("res header " + JSON.stringify(res.headers));
      // if(res.data.error === "email is invalid" || res.data.message === "password is invalid" || res.data.message === "Please provide correct password!" || res.data.message != "undefined" || res.data.message != undefined)
      if (res.data.message === 'Invalid otp!') {
        console.log("error block of the twofa enable");
      dispatch({
          type: LOGIN_FAIL,
          payload: { payload: res.data.message },
        });
        sendLogs("Org Login failed", res.data, "actions/auth.js");
      } else if (res.data.statusCode === 200 ) {
        
        // if (res.headers.authorization) {
        //   console.log("localhost auth",res.headers.authorization)
        //   setAuthToken(res.headers.authorization);
        // }
        //console.log(authMode);
        if (authStatus === false) {
          dispatch({
            type: LOGIN_SUCCESS_LOCAL,
            payload: { headers: res.headers, payload: res.data },
          });
          console.log('inside if auth')
          console.log("login success!!!")
          console.log("check1234",ls.get("token"))
          const deleteTokenRes = await axios.post(environment.deleteblacklisttoken , null, {
            headers: {
              "Content-Type": "application/json",
              Authorization:ls.get("token") ,
            },
          });
      

          sendLogs(
            "Org Login success",
            "Org logged in successfully",
            "actions/auth.js"
          );
        } 
        else {
          console.log("sandbox");
          
          //login in test mode
          dispatch({
            type: LOGIN_SANDBOX_TWOFACTOR,
            payload: { headers: res.headers, payload: res.data },
          });
          sendLogs(
            "Sandbox Login",
            "Sandbox logged in successfully",
            "actions/auth.js"
          );
        }
      } else {
        // //console.log("res " + JSON.stringify(res.headers));
        //console.log("res " + res);
        console.log("login fail")
        dispatch({
          type: LOGIN_FAIL,
          payload: { payload: res.data },
        });
      }
    } catch (error) {
      console.log("error block",error.response?.data);
      dispatch({
        type: ENABLE_TWO_FACTOR_ERROR,
        payload: { payload: error.response.data },
      });
    }
    // }
  };


// Login User for fusion auth

export const Fusionlogin = (user, authMode ) => async (dispatch) => {
  ls.remove("token");
  ls.remove("alldata");
  ls.remove("apikey");
  ls.remove("email");
  ls.remove("orgid");
  ls.remove("roles");
  ls.set("roles", "null");
  ls.remove("stripeid");
  ls.remove("planid");
  ls.clear();

  const bodyEmail = { email: user.email };
  const AuthToken = Cookies.get("app.idt");
  console.log("inside the Login action here is Login Email", bodyEmail);
  console.log("inside the Login action  here is app.idt token", AuthToken);


  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const fusionConfig={
    headers:{
      "Content-Type": "application/json",
      "X-FusionAuth-TenantId": "84f65a70-9b87-fea9-0f00-302b2597bf03",
      "Authorization": "l6zwuy71MbjlnJH-NRUGCbI5mOgZPVwNHFDXU_efQUv43fKucd_a85p_",
    }

  }

  try {
    console.log("inside try ", bodyEmail);
    const res = await axios.post(environment.saveOrgData, bodyEmail, config);
    console.log('here is the userId',res.data.doc.id);
    //loccalstorage
    ls.set('uuid',res.data.doc.id)

    if(res){

    const TokenRes = await axios.get(`https://fauth2.diro.live/api/jwt/refresh?userId=${res.data.doc.id}`,fusionConfig);
    
    //refresh token from fusion auth 
    const refreshToken = TokenRes.data.refreshTokens[0].token;
    ls.set('refreshToken', refreshToken);
    

    console.log('here is the refresh token ',refreshToken);

    console.log("zuul api response", res);
    
    console.log('here is header response',res.headers)
    const config = {
      headers: {
          "Content-Type": "application/json",
      }
    };
    const accessTokenRes = await axios.post(environment.accesstokenUrl, { refreshToken }, config );
    // Retrieve the access token from the response headers
    const accessToken = accessTokenRes.headers.authorization;

    console.log('accesstoken is ;',accessToken)
    const deleteTokenRes = await axios.post(environment.deleteblacklisttoken , null, {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    });
    console.log('here is the access token', accessToken);

    if(refreshToken)
        if (res.data.error) {
          console.log("inside the error dipatch block");
          dispatch({
            type: LOGIN_FAIL,
            payload: { payload: res.data },
          });
          sendLogs("Org Login failed", res.data, "actions/auth.js");
        } else if (
          res.data.message === "success" ||res.data.message === "Success!") {
            //need to work here
          if (accessToken) {
            console.log("inside the authToken block. -> res.headers.authorization", accessToken);
            setAuthToken(accessToken);
          }
          if (authMode === 1 || authMode === "1") {
            console.log("inside the authmode block");
            console.log("my authmode",res.data,",", res.data)
            dispatch({
              type: LOGIN_SUCCESS,
              payload: {
                headers: res.headers,
                payload: res.data,
                token: accessToken

              },
            });
            sendLogs(
              "Org Login success",
              "Org logged in successfully",
              "actions/auth.js"
            );
          } else {
            console.log("inside the sandbox block");
            dispatch({
              type: LOGIN_SANDBOX,
              payload: { headers: res.headers, payload: res.data },
            });
            sendLogs(
              "Sandbox Login",
              "Sandbox logged in successfully",
              "actions/auth.js"
            );
          }
        } else {
          console.log("inside the Fail Login block");
          dispatch({
            type: LOGIN_FAIL,
            payload: { payload: res.data },
          });
        }
      } 
    else{
      console.log("inside the error dipatch block");
      dispatch({
        type: LOGIN_FAIL,
        payload: { payload: res.data },
      });
      sendLogs("Org Login failed", res.data, "actions/auth.js");
    }
  }
  catch (error) {
    console.log(error);
    dispatch({
      type: ENABLE_TWO_FACTOR_ERROR,
      payload: { payload: error.response.data },
    });
  }
};


//login otp
export const sendLoginOtp = (twoFactorId,methodId) => async (dispatch) => {
  try{
    const body = {
      twoFactorId:twoFactorId,
      methodId:methodId
    }

    const res = await axios.post(environment.sendLoginOtp,body)
    console.log("otp sent!!!")

  }catch(error){
    console.log(error);
  }
}

// };

// Login User
export const forgoruser = (forgotData, history) => async (dispatch) => {
  dispatch({ type: START_REG_LOADER });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(environment.forgot, forgotData, config);
    // ls.set('Authorization', setAuthToken);
    // ls.get('Authorization');
    dispatch({
      type: FORGOT_PASSWORD,
      payload: { payload: res.data },
    });
  } catch (err) {
    console.log("in auth action forgoruser");
    dispatch({
      type: FORGET_PASSWORD_FAIL,
      payload: { payload: err },
    });
  }
};

// reset User
export const resetPassword = (forgotData, history) => async (dispatch) => {

  dispatch({ type: START_REG_LOADER });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(environment.resetpassword, forgotData, config);
    // ls.set('Authorization', setAuthToken);
    // ls.get('Authorization');
    console.log(res.data.message);
    if (res.data.statusCode === 200) {
      dispatch({
        type: PASSWORD_ERROR,
        payload: false,
      });
      //   console.log("2",res.data.message);
      //history.push("/authentication/login");
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      
      if (forgotData.userEmail){
        const body = {
          email: forgotData.userEmail
        };
        const response = await axios.delete(environment.expireRefreshtoken, { ...config, data: body });
      }
      
      
      history.push("/login");
      // return res;
    } else if (res.data.statusCode === 410) {
      dispatch({
        type: RESET_LINK_EXPIRED,
        payload: true,
      });
    } else if (
      res.data.message ===
      "Your new password cannot be the same as your previous password."
    ) {
      dispatch({
        type: PASSWORD_ERROR,
        payload: true,
      });
      return res;
    }
  } catch (err) {
    console.log("in auth action resetpassword");
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Login User
export const updatePassword = (forgotData, history) => async (dispatch) => {
  dispatch({ type: START_REG_LOADER });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    console.log("in tr");
    const res = await axios.post(
      environment.updatepassword,
      forgotData,
      config
    );
    // ls.set('Authorization', setAuthToken);
    // ls.get('Authorization');
    console.log(res.data.message);

    if (res.data.message === "success") {
      console.log("in if");
      history.push("/authentication/login");
    }
  } catch (err) {
    console.log("in updatepassword");
    dispatch({
      type: AUTH_ERROR,
    });
  }
};




export const changeMode = (data) => (dispatch) => {
  console.log('authmode change',data)
  dispatch({
    type: AUTH_MODE,
    payload: data,
  });
  sendLogs("changeMode", "Sandbox mode switched", "actions/auth.js");
};

//Generating QR code 
export const qrCode=()=> async (dispatch)=>{

  dispatch({type:QR_CODE_START})
  try{
    const res= await axios.post(environment.qrCode, null, {
      headers: {
        'Content-Type': 'text/html', 
      },
    });
    console.log("QR data",res.data)
    dispatch({
    type:QR_CODE,
    payload:res.data
    })
  }catch(error)
  {
    console.log('QR ERROR', error)
    dispatch({type:QR_CODE_FAIL})
  }
  
}

//to generate payload 
const generateEmailPayload = (toEmail, recoveryCodes) => {
  const username =toEmail.split("@")[0];
  const userNameUppercase = username.charAt(0).toUpperCase() + username.slice(1);
  return {
      to: toEmail,
      port: environment.emailPort,
      host: environment.emailHost,
      user: environment.emailAccount,
      password: environment.emailPassword,
      html:` <strong>Hi ${userNameUppercase},</strong> <br/> To complete your login, please use the following two-factor authentication (2FA) code: <br>
            ${recoveryCodes[0]} &nbsp; &nbsp; &nbsp;  ${recoveryCodes[1]} &nbsp; &nbsp; &nbsp; ${recoveryCodes[2]}  <br/>
            ${recoveryCodes[3]} &nbsp; &nbsp; &nbsp;  ${recoveryCodes[4]} &nbsp; &nbsp; &nbsp; ${recoveryCodes[5]}  <br/> 
            ${recoveryCodes[6]} &nbsp; &nbsp; &nbsp;  ${recoveryCodes[7]} &nbsp; &nbsp; &nbsp; ${recoveryCodes[8]}  <br/>   
            ${recoveryCodes[9]} <br/>
            In case of any help required, reach out to us at support@diro.io.<br/><br/>
            Best regards,<br/>
            DIRO Support Team <br/>
            <img src='https://prod.dirolabs.com/Zuul-1.0/organization-2.0/getlogo' alt='diro' style='width: 50px; height: 27px; float: left; margin-top: 5px;'>`,
      subject: "Your DIRO Account Recovery Codes"
  };
};

//SEND OTP
export const sendOtp = (email) => async (dispatch) =>{
  const body={
    email:email
  }
  try {
    const res= await axios.post(environment.sendOtp,body)
    console.log(res,"sent otp")
    return true;
  }
  catch(error){
    console.log(error,"error while send otp")
    return false;
  }
} 

//enable two factor for authenticator
export const enableTwoFactor=(email,code,method,secret,twoFactorId)=> async (dispatch)=>{
  if(method === "Authenticator app"){
    method='authenticator';
  }
 const body={email,code,method,secret,twoFactorId}
 try{
 const res =await axios.post(environment.enableTwoFactor,body)
 console.log('response is : ',res)
 dispatch({
  type:ENABLE_TWO_FACTOR,
  payload:res.data
 })
 const recoveryCodes = res.data.data.recoveryCodes;
 const emailPayload = generateEmailPayload(email, recoveryCodes);

 const emailReminderUrl = 'https://api1.diro.live/emailReminder';
 const emailReminderRes = await axios.post(emailReminderUrl, emailPayload);
 console.log('Email reminder response:', emailReminderRes.data)
 console.log(res.data.data.code)
 dispatch(twoFactorLogin(email, res.data.data.code, twoFactorId, 1));
 
}
catch(error){
  // alert('test error');
  console.log(error.response.data,'payload msg')
  dispatch({type:ENABLE_TWO_FACTOR_ERROR,payload: error.response.data})
  console.error('Error enabling two-factor authentication:', error.response.data.msg);
  }
}

//enable two factor for Email
export const enableTwoFactorEmail=(email,code,method,twoFactorId)=> async (dispatch)=>{
  if(method === "Email address"){
    method='email';
  }
  const body={email,code,method,twoFactorId}
  try{
  const res =await axios.post(environment.enableTwoFactor,body)
  console.log('response is : ',res)
  dispatch({
   type:ENABLE_TWO_FACTOR,
   payload:res.data
  })
  const recoveryCodes = res.data.data.recoveryCodes;
  const emailPayload = generateEmailPayload(email, recoveryCodes);
 
  const emailReminderUrl = 'https://api1.diro.live/emailReminder';
  const emailReminderRes = await axios.post(emailReminderUrl, emailPayload);
  console.log('Email reminder response:', emailReminderRes.data)
  console.log(res.data.data.code)
  dispatch(twoFactorLogin(email, res.data.data.code, twoFactorId, 1));
  
 }
 catch(error){
  // alert('test error');
  console.error('Error enabling two-factor authentication:2', error);
    dispatch({type:ENABLE_TWO_FACTOR_ERROR,payload:error?.response?.data})
  //  console.error('Error enabling two-factor authentication:', error);
   }
 }

 export const switchModeServer = (data) => async (dispatch) => {
  const switchPayload = { emailId: data.email, sandbox: data.sandbox };

  try {
      const modeServer = await axios.post(environment.switchServer, switchPayload);
      console.log("Mode Change:", modeServer.data.sandbox);

      dispatch({
          type: AUTH_MODE,
          payload: modeServer.data.sandbox === true ? 2 : 1,
      });

      console.log("Mode Server:", JSON.stringify(modeServer.data));
  } catch (error) {
      console.error("Error in switching the Modes:", error);

      if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
          console.error("Response headers:", error.response.headers);
      } else if (error.request) {
          console.error("Request data:", error.request);
      } else {
          console.error("Error message:", error.message);
      }

      // Optionally dispatch a fallback action
      dispatch({
          type: AUTH_MODE,
          payload: 1, // Default mode in case of error
      });
  }
};
  

export const changeModeLogout = (data) => (dispatch) => {
  console.log('change mode logout',data)
  dispatch({
    type: AUTH_MODE,
    payload: data,
  });
  sendLogs(
    "Org Logout",
    "Logged out successfully and profile cleared",
    "actions/auth.js"
  );
  // dispatch({ type: CLEAR_PROFILE });
  // dispatch({ type: LOGOUT });
};

const blacklistTokenAPI = () => {

  const token = ls.get("token");
  const authHeader = `Bearer ${token}`;
  axios.defaults.headers.common["Authorization"] = authHeader;
  axios.post(environment.blacklisttoken)
      .then(response => {
          console.log('Token blacklisted successfully');
      })
      .catch(error => {
         
          console.error('Error blacklisting token:', error);
      });
};

//logout action 
export const logout = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  blacklistTokenAPI();

  // Logout to expire the refresh token
  const email=ls.get('email')
  const body = {
    email: email
  };

  try {
    const response = await axios.delete(environment.expireRefreshtoken, { ...config, data: body });
    console.log(response);
    console.log('Response:', response.data);
    dispatch({ type: CLEAR_PROFILE });
    dispatch({ type: LOGOUT });
  } catch (error) {
    console.error('Error:', error);
  }
};

//new logout 

export const logoutFA = () => (dispatch) => {
  
  //to clear the local storage
  dispatch({ type: CLEAR_PROFILE });
  dispatch({ type: LOGOUT });

  //logout expire the refreshtoken
  const url = environment.expireRefreshToken;
  const applicationIdlocal = environment.aplicationFusionauthId;
  const userIdlocal = ls.get('uuid')

  const headers = {
    Authorization: environment.fusionAuthApiKey,
  };

  axios.delete(url, {
    headers: headers,
    params: {
      applicationId: applicationIdlocal,
      userId: userIdlocal
    }
  })
    .then(response => {
      console.log('Response:', response.data);
    })
    .catch(error => {
      console.error('Error:', error);
    });

};


console.log("ls.get(token) ", ls.get("token"));

if (localStorage.getItem("token") || ls.get("token") === "") {
  logout();
}
