import axios from "axios";

import {
  GET_LOGS,
  CLEAR_LOG,
  LOGS_ERROR,
  CLEAR_TABLE,
  GET_LOGS_HEADER,
  GET_LOGS_DATA,
  GET_LOGS_FORM,
  FOR_DEVELOPERS_NOTIFICATION_HEADER,
  AFTER_DOCUMENT_SUBMISSION_HEADER,
  GET_SETUP_VERIFICATION_BTN_HEADER,
  GET_SETUP_ORGANIZATION_HEADER,
  GET_NOCODE_INTEGRATION_HEADER,
  BEFORE_YOU_GO_LIVE,
  DELETE_DOC,
  USER_PERMISION_LANDING,
  CONFIGURE_VERIFICATION,
  DURING_ADVANCE_VERIFICATION,
  SET_YOUR_LOGO,
  //TRIGGER_COMPARE_VERIFICATION
} from "./types";
import { env as environment } from "./environment";
import refreshAuth from "./refreshAuth";
import ls from "localstorage-slim";

ls.config.encrypt = true;
axios.defaults.headers.common["Authorization"] = ls.get("token");

//var apikey = ls.get('apikey');
var offset = 0;
var limitVar = 10;

export const getLogs = (li) => async (dispatch) => {
  dispatch({type:CLEAR_TABLE})
  dispatch({ type: CLEAR_LOG });
  dispatch({ type: GET_LOGS_HEADER });
  var json = {
    apikey: ls.get("apikey"),
    offset: offset,
    limit: limitVar,
    order: "desc",
  };
  try {
    const res = await axios.post(environment.callbacklogs, json);
    //console.log(res);
    if (res.data === "" || res.status===204) {
      console.log("No logs found");
      dispatch({
        type: LOGS_ERROR,
        payload: {
          error: "No logs found",
          timestamp: new Date().toISOString(),
          errorCode: res.status
        },
      });
    }  else {
      dispatch({
        type: GET_LOGS,
        payload: res.data.logs,
      });
    }
  } catch (err) {
    if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
      dispatch(refreshAuth("getLogs"));
    } else {
      dispatch({
        type: LOGS_ERROR,
        payload: err.response,
      });
    }
  }
};

export const getLogsdata = (data,search) => async (dispatch) => {
    dispatch({ type: CLEAR_LOG });
  // dispatch({ type: CLEAR_TABLE });

  var json = {
    apikey: ls.get("apikey"),
    offset: data,
    limit: 10,
    order: "desc",
    // search:search,
  };

  if (search) {
    json.search = search;
}
  try {
    const res = await axios.post(environment.callbacklogs, json);

    if (res.data === "" || res.status===204) {
      console.log("No logs found");
      dispatch({
        type: LOGS_ERROR,
        payload: {
          error: "No logs found",
          timestamp: new Date().toISOString(),
          errorCode: res.status
        },
      });
    }  else {
      dispatch({
        type: GET_LOGS,
        payload: res.data.logs,
      });
    }
  } catch (err) {
    if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
      dispatch(refreshAuth("getLogsdata", data));
    } else {
      dispatch({
        type: LOGS_ERROR,
        payload: err.response,
      });
    }
  }
};

export const getlogform = () => async (dispatch) => {
  dispatch({ type: GET_LOGS_FORM   });
 
};


export const getlogdata = () => async (dispatch) => {
  dispatch({ type: GET_LOGS_DATA });
};

//---- Send logs to graylog ----
export const sendLogs = async (shortmsg, longmsg, facility, level) => {
  let url = "https://api.diro.io/logs/logs";
  try {
    var logMessage = {
      // version: "2.0",
      // host: "client-portal",
      short_message: shortmsg,
      full_message: longmsg,
      timestamp: Date.now() / 1000,
      level: level,
      facility: facility,
      app_name: "client-portal",
      user: ls.get("email"),
      _ENV: environment.env,
      // "_sessionid": $routeParams.params,
      // "tsuser" :  $rootScope.tsuser,
    };
    const res = await axios.post(url, logMessage);
    console.log(res);
  } catch (err) {
    console.log(err);
    // dispatch({
    // 	type: ORG_ERROR,
    // 	payload: err.response,
    // });
  }
};

export const noCodeIntegrationHeader = () => async (dispatch) => {
  dispatch({ type: GET_NOCODE_INTEGRATION_HEADER });
};

export const setUpOrganizationHeader = () => async (dispatch) => {
  dispatch({ type: GET_SETUP_ORGANIZATION_HEADER });
};

export const setUpVerificationBtnHeader = () => async (dispatch) => {
  dispatch({ type: GET_SETUP_VERIFICATION_BTN_HEADER });
};

export const afterDocumentSubHeader = () => async (dispatch) => {
  dispatch({ type: AFTER_DOCUMENT_SUBMISSION_HEADER });
};

export const forDevelopersNotification = () => async (dispatch) => {
  dispatch({ type: FOR_DEVELOPERS_NOTIFICATION_HEADER });
};

export const forBeforeGolive = () => async (dispatch) => {
  dispatch({ type: BEFORE_YOU_GO_LIVE });
};
export const userPermisiomLanding = () => async (dispatch) => {
  dispatch({ type: USER_PERMISION_LANDING });
};
export const configureVerification = () => async (dispatch) => {
  dispatch({ type: CONFIGURE_VERIFICATION })
};
export const duringVerify = () => async (dispatch) => {
  dispatch({ type: DURING_ADVANCE_VERIFICATION })
}
export const SetYourLogo = () => async (dispatch) => {
  dispatch({ type: SET_YOUR_LOGO })
}
export const Delete_Doc = () => async (dispatch) => {
  dispatch ({type:DELETE_DOC})
}
