// USER
export const USER_LOADED = "USER_LOADED";
export const LOGOUT = "LOGOUT";
export const ACCOUNT_DELETED = "ACCOUNT_DELETED";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const GET_COUNTRYS = "GET_COUNTRYS";
export const QR_CODE="QR_CODE";
export const QR_CODE_FAIL = "QR_CODE_FAIL";
export const QR_CODE_START = "QR_CODE_START";
export const ENABLE_TWO_FACTOR_ERROR="ENABLE_TWO_FACTOR_ERROR";
// export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
// export const LOGIN_FAIL = 'LOGIN_FAIL';
// export const SET_ALERT = 'SET_ALERT';
// export const REMOVE_ALERT = 'REMOVE_ALERT';

// LAYOUT
export const LOGO_BG = "LOGO_BG";
export const NAVBAR_BG = "NAVBAR_BG";
export const SIDEBAR_BG = "SIDEBAR_BG";
export const THEME = "THEME";
export const DIRECTION = "DIRECTION";
export const SIDEBAR_POSITION = "SIDEBAR_POSITION";
export const HEADER_POSITION = "HEADER_POSITION";
export const LAYOUT = "LAYOUT";
export const SIDEBAR_TYPE = "SIDEBAR_TYPE";

//TABLE
export const GET_REQUESTS = "GET_REQUESTS";
export const GET_PENDINGS = "GET_PENDINGS";
export const GET_COMPLETED = "GET_COMPLETED";
export const GET_REVIEWS = "GET_REVIEWS";
export const GET_APPROVES = "GET_APPROVES";
export const GET_REJECTS = "GET_REJECTS";
export const GET_SESSION_REPORT = "GET_SESSION_REPORT";
export const GET_SEARCH_RESULT = "GET_SEARCH_RESULT";
export const CLEAR_TABLE = "CLEAR_TABLE";
export const TABLE_ERROR = "TABLE_ERROR";
export const TABLE_LOADER = "TABLE_LOADER";
export const SEARCH_GET_TRUE = "SEARCH_GET_TRUE";
export const SEARCH_API_FALSE = "SEARCH_API_FALSE";
export const CALL_BACK_LOGS="CALL_BACK_LOGS"

//BUTTON
export const GET_BUTTON = "GET_BUTTON";
export const GET_BUTTONS = "GET_BUTTONS";
export const POST_BUTTON = "POST_BUTTON";
export const CLEAR_BUTTON = "CLEAR_BUTTON";
export const ADD_BUTTON = "ADD_BUTTON";
export const DELETE_BUTTON = "DELETE_BUTTON";
export const REMOVE_BUTTON_ALERT = "REMOVE_BUTTON_ALERT";
export const UPDATE_BUTTON = "UPDATE_BUTTON";
export const GET_MASTERFIELDDATA = "GET_MASTERFIELDDATA";
export const GET_EMAILREMINDER_DATA = "GET_EMAILREMINDER_DATA";
export const TEST_EMAILREMINDER = "TEST_EMAILREMINDER";
export const TESTEMAIL_LOADER = "TESTEMAIL_LOADER";
export const BUTTON_DELETE = "BUTTON_DELETE";
export const BUTTON_DEL_SUCCESS = "BUTTON_DEL_SUCCESS";
export const BUTTON_DEL_ERROR = "BUTTON_DEL_ERROR";

// LOGS
export const GET_LOGS = "GET_LOGS";
export const GET_LOG = "GET_LOG";
export const LOGS_ERROR = "LOGS_ERROR";
export const CLEAR_LOG = "CLEAR_LOG";
export const GET_LOGS_DATA = "GET_LOGS_DATA";
export const GET_LOGS_FORM = "GET_LOGS_FORM";
export const HEADER_SEARCH_INPUT = "HEADER_SEARCH_INPUT";

// Organization Details
export const CLEAR_ORG = "CLEAR_ORG";
export const GET_ORG = "GET_ORG";
export const ORG_ERROR = "ORG_ERROR";
export const UPDATE_ORG = "UPDATE_ORG";
export const GET_ORG_ITEM = "GET_ORG_ITEM";
export const REMOVE_BACKGROUND = "REMOVE_BACKGROUND";
export const REMOVE_BACKGROUND_ERROR = "REMOVE_BACKGROUND_ERROR";
// BILLING
export const GET_TRANSACTION = "GET_TRANSACTION";
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const TRANSACTION_ERROR = "TRANSACTION_ERROR";
export const GET_BILLING = "GET_BILLING";
export const BILLING_ERROR = "BILLING_ERROR";

//Header subheading
export const GET_REQUESTS_HEADER = "GET_REQUESTS_HEADER";
export const GET_PENDINGS_HEADER = "GET_PENDINGS_HEADER";
export const GET_COMPLETED_HEADER = "GET_COMPLETED_HEADER";
export const GET_REVIEWS_HEADER = "GET_REVIEWS_HEADER";
export const GET_APPROVES_HEADER = "GET_APPROVES_HEADER";
export const GET_REJECTS_HEADER = "GET_REJECTS_HEADER";
export const GET_VALIDATION_HEADER = "GET_VALIDATION_HEADER";
export const GET_COVERAGE_HEADER = "GET_COVERAGE_HEADER";
export const GET_LOGS_HEADER = "GET_LOGS_HEADER";
export const GET_REFERENCE_HEADER = "GET_REFERENCE_HEADER";
export const GET_USER_HEADER = "GET_USER_HEADER";
export const GET_BILLING_HEADER = "GET_BILLING_HEADER";
export const GET_ORG_HEADER = "GET_ORG_HEADER";
export const GET_BUTTON_SETTING_HEADER = "GET_BUTTON_SETTING_HEADER";
export const GET_VIEW_DOC_HEADER = "GET_VIEW_DOC_HEADER";
export const GET_API_BUTTON = "GET_API_BUTTON";

//Alert
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_ERRORS = "AUTH_ERRORS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const AUTH_MODE = "AUTH_MODE";
export const LOGIN_SANDBOX = "LOGIN_SANDBOX";
export const LOGIN_SUCCESS_LOCAL = "LOGIN_SUCCESS_LOCAL";
export const LOGIN_AUTHENITICATED = "LOGIN_AUTHENITICATED";
export const ENABLE_TWO_FACTOR ="ENABLE_TWO_FACTOR";
export const LOGIN_SANDBOX_TWOFACTOR = "LOGIN_SANDBOX_TWOFACTOR";
export const SEE_COVERAGE_ERROR = "SEE-COVERAGE_ERROR";


//USERS
export const GET_USER = "GET_USER";
export const DELETE_USERS = "DELETE_USERS";
export const EDIT_USERS = "EDIT_USERS";
export const ADD_USER = "ADD_USER";
export const GET_USERS = "GET_USERS";
export const CLEAR_USER = "CLEAR_USER";
export const GET_USERS_ERROR = "GET_USERS_ERROR";
export const ADD_USER_ERROR = "ADD_USER_ERROR";
export const EDIT_USER = "EDIT_USER";
export const TRANSFER_OWNERSHIP="TRANSFER_OWNERSHIP";

export const GET_INTEGRATION_HEADER = "GET_INTEGRATION_HEADER";

//Callback
export const UPDATE_CALLBACK = "UPDATE_CALLBACK";
export const CALLBACK_ERROR = "CALLBACK_ERROR";

//Loader
export const START_REG_LOADER = "START_REG_LOADER";
export const START_LOGIN_LOADER = "START_LOGIN_LOADER";
export const START_ORG_LOADER = "START_ORG_LOADER";

//User alert
export const STOP_USER_ALERT = "STOP_USER_ALERT";

//Request document
export const GET_TEST_PREVIEW = "GET_TEST_PREVIEW";
export const GET_SEND_REQUEST = "GET_SEND_REQUEST";
export const GET_INVITE_LINK = "GET_INVITE_LINK";
export const REQ_ERROR = "REQ_ERROR";
export const REQ_LOADER = "REQ_LOADER";
export const EMPTY_BLANK_URL = "EMPTY_BLANK_URL";

export const LOAD_BUTTON = "LOAD_BUTTON";

export const REFRESH_TOKEN = "REFRESH_TOKEN";

export const REMOVE_AUTH_ERROR = "REMOVE_AUTH_ERROR";

export const POST_FEEDBACK_FORM = "POST_FEEDBACK_FORM";
export const REMOVE_FEEDBACK_ALERT = "REMOVE_FEEDBACK_ALERT";

export const GET_PDF_DATA = "GET_PDF_DATA";
export const GET_DOCUMENT_DATA = "GET_DOCUMENT_DATA";
export const VIEW_DOC_LOADER = "VIEW_DOC_LOADER";

export const GET_APPROVE_DATA = "GET_APPROVE_DATA";
export const GET_REJECT_DATA = "GET_REJECT_DATA";
export const GET_VIEW_DOC_MESSAGE = "GET_VIEW_DOC_MESSAGE";
export const REMOVE_VIEW_DOC_MESSAGE = "REMOVE_VIEW_DOC_MESSAGE";

export const LOAD_COUNTRY_LIST = "LOAD_COUNTRY_LIST";
export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST";
export const ERR_COUNTRY_LIST = "ERR_COUNTRY_LIST";
export const LOAD_COUNTRY_LINKS = "LOAD_COUNTRY_LINKS";
export const GET_COUNTRY_LINKS = "GET_COUNTRY_LINKS";
export const ERR_COUNTRY_LINKS = "ERR_COUNTRY_LINKS";
export const RESET_COUNTRY = "RESET_COUNTRY";
export const RESET_DIRECT_LINK = "RESET_DIRECT_LINK";
export const SET_ALPHA_CODE = "SET_ALPHA_CODE";
// export const LOAD_SEARCH_LINKS = "LOAD_SEARCH_LINKS";
export const SET_CATEGORY = "SET_CATEGORY";
// export const LOAD_GETSEARCH_LINKS = "LOAD_GETSEARCH_LINKS";
// export const LOAD_ERRSEARCH_LINKS = "LOAD_SEAERRRCH_LINKS";
// export const HIT_SEARCH_LINKS = "HIT_SEARCH_LINKS";

export const PDF_LOADER = "PDF_LOADER";
export const PDF_TO_JSON_DATA = "PDF_TO_JSON_DATA";
export const SEND_REVIEW_DATA = "SEND_REVIEW_DATA";
export const SEND_REVIEW_RESET = "SEND_REVIEW_RESET";
export const PDF_TO_JSON_ERROR = "PDF_TO_JSON_ERROR";
export const EXTRACT_TRANSACTION_ERROR = "EXTRACT_TRANSACTION_ERROR";
export const EXTRACT_TRANSACTION_DATA = "EXTRACT_TRANSACTION_DATA";
export const EXTRACT_TRANSACTION_LOADING = 'EXTRACT_TRANSACTION_LOADING';
export const REDO_TRANSACTION_DOC_SUCCESS = "REDO_TRANSACTION_DOC_SUCCESS";
export const REDO_TRANSACTION_DOC_FAILURE ="REDO_TRANSACTION_DOC_FAILURE";
export const REDO_TRANSACTION_DOC_LOADING  ="REDO_TRANSACTION_DOC_LOADING ";
export const GET_AUTO_NAV_DATA = "GET_AUTO_NAV_DATA";
export const GET_AUTO_NAV_DATA_FAILURE ="GET_AUTO_NAV_DATA_FAILURE" ;
export const GET_ALL_USER_DATA = "GET_ALL_USER_DATA";
export const S3_BUCKET_DATA = "S3_BUCKET_DATA";

export const GET_TRIGGER_HEADER = "GET_TRIGGER_HEADER";
export const FOR_DEVELOPERS_NOTIFICATION_HEADER =
  "FOR_DEVELOPERS_NOTIFICATION_HEADER";
export const AFTER_DOCUMENT_SUBMISSION_HEADER =
  "AFTER_DOCUMENT_SUBMISSION_HEADER";
export const GET_SETUP_VERIFICATION_BTN_HEADER =
  "GET_SETUP_VERIFICATION_BTN_HEADER";
export const GET_SETUP_ORGANIZATION_HEADER = "GET_SETUP_ORGANIZATION_HEADER";
export const GET_NOCODE_INTEGRATION_HEADER = "GET_NOCODE_INTEGRATION_HEADER";
export const BEFORE_YOU_GO_LIVE = "BEFORE_YOU_GO_LIVE";
export const USER_PERMISION_LANDING='USER_PERMISION_LANDING';
export const CONFIGURE_VERIFICATION="CONFIGURE_VERIFICATION";
export const DURING_ADVANCE_VERIFICATION="DURING_ADVANCE_VERIFICATION"
export const TRIGGER_COMPARE_VERIFICATION="TRIGGER_COMPARE_VERIFICATION"
export const SET_YOUR_LOGO="SET_YOUR_LOGO"
export const PASSWORD_ERROR = "PASSWORD_ERROR";
export const RESET_LINK_EXPIRED="RESET_LINK_EXPIRED";
export const DELETE_DOC ="DELETE_DOC";
export const FORGET_PASSWORD_FAIL = "FORGET_PASSWORD_FAIL";
export const GET_SETUP_HMACKEY_HEADER = "GET_SETUP_HMACKEY_HEADER";

export const DELETE_TABLE_DATA_SUCCESS = 'DELETE_TABLE_DATA_SUCCESS';
export const UPDATE_TABLE_DATA="UPDATE_TABLE_DATA";
export const CREATE_SOURCE="CREATE_SOURCE";